"use client";
import * as React from "react";
import { Row } from "saladsoft-primitive";
import styled from "styled-components";

const Section07 = () => {
  return (
    <StayContainer>
      <ImageWrapper>
        <StyledSection>
          <ContentWrapper>
            <TextContent>
              <span>버틀러리와 함께하실</span>
              <BoldText>한옥 소유주와 투자자를 찾습니다</BoldText>
            </TextContent>
            <ConsultButton onClick={() => {
              window.open('https://proudlee.typeform.com/to/HEPzMsY0', '_blank');
            }}>상담하기</ConsultButton>
          </ContentWrapper>
        </StyledSection>
      </ImageWrapper>
    </StayContainer>
  );
};


const StyledSection = styled.section`
  width: 100%;
  min-height: 191px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 409px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  @media (max-width: 991px) {
    width: 90%;
    max-width: 409px;
  }

  @media (max-width: 640px) {
    width: 95%;
    gap: 40px;
  }
`;

const TextContent = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Pretendard", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.08px;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 28px;
  }

  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

const BoldText = styled.span`
  font-weight: 700;
  display: block;
`;

const ConsultButton = styled.button`
  padding: 24px 80px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  text-align: center;
  font-family: "Pretendard", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.042px;
  cursor: pointer;

  @media (max-width: 640px) {
    padding: 20px 60px;
    font-size: 16px;
  }
`;

const StayContainer = styled.div`
  margin: 0 auto;

`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/section_100.png');
  background-size: cover;
  background-position: center;
  height:579px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Section07;
