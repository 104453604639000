import CancellationPolicy from 'components/commons/CancellationPolicy';
import MobileTab from 'components/commons/mobile/MobileTab';
import DetailPhotoBox from 'components/commons/pc/DetailPhotoBox';
import Tab from 'components/commons/pc/Tab';
import Amenity from 'components/pages/listingPage/Amenity';
import CheckPoint from 'components/pages/listingPage/CheckPoint';
import Detail from 'components/pages/listingPage/Detail';
import DetailReservationBox from 'components/pages/listingPage/DetailReservationBox';
import ListingSummary from 'components/pages/listingPage/ListingSummary';
import Location from 'components/pages/listingPage/Location';
import NearBy from 'components/pages/listingPage/NearBy';
import Plan from 'components/pages/listingPage/Plan';
import PriceBox from 'components/pages/listingPage/PriceBox';
import RecommendListings from 'components/pages/listingPage/RecommendListings';
import PageContainer from 'containers/pc/PageContainer';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import i18n from 'i18n';
import { getUrlParam } from 'libs/helper/utils';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Row, useMediaContext } from 'saladsoft-primitive';
import { ListingPriceDto, UserProfileDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { BookInfo } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import useToken from 'hooks/useToken';
import GalleryViewer from 'components/pages/listingPage/GalleryViewer';
import GalleryViewerMobile from 'components/pages/listingPage/GalleryViewerMobile';

const DetailPage = observer(() => {
  const targetRefs = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { validUser } = useToken();

  const { uiStore, listingStore, filterStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const { listingId } = useParams();

  const [activeTab, setActiveTab] = useState<number>(0); // 현재 활성화된 탭
  const [listingPrice, setListingPrice] = useState<ListingPriceDto>();
  const [userInfo, setUserInfo] = useState<UserProfileDto>();
  const [priceType, setPriceType] = useState<'actual' | 'lowest'>('actual');
  const [isGalleryViewerOpen, setIsGalleryViewerOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  const handleTabChange = (id: number) => {
    setActiveTab(id);
    const targetElement = targetRefs.current[id];

    if (targetElement) {
      const offset = 110; // 헤더 높이와 여유 공간을 위한 오프셋
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth', // 부드러운 스크롤 효과 추가
      });
    }
  };

  const handleConfirm = () => {
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    // const guests = getUrlParam('guests');

    // guests 값이 없으면 listing의 minGuest 값을 사용
    let guests = getUrlParam('guests');
    if (!guests || guests === 'null' || guests === 'undefined') {
      guests = listingStore?.listing?.minGuest?.toString() || '2';
    }
    // 예약 정보를 localStorage에 저장
    const bookInfo: BookInfo = {
      startDate: startDate ?? '',
      endDate: endDate ?? '',
      guests: Number(guests) ?? 2,
      listingId: Number(listingId),
    };
    localStorage.setItem('bookInfo', JSON.stringify(bookInfo));

    navigate(`/login`);
  };

  const ConfirmModalContent = () => {
    return (
      <ModalContainer>
        <ModalContent>
          <Title>{t('reservation.confirmReservation')}</Title>
          <Description>{t('reservation.confirmSignUpDescription')}</Description>
        </ModalContent>
        <Row gap={10} justifyContent="center">
          <CancelButton
            onClick={() => {
              uiStore?.universalModal.close();
              navigateToBook();
            }}
          >
            {t('reservation.nonMemberReservation')}
          </CancelButton>
          <ConfirmButton
            onClick={() => {
              uiStore?.universalModal.close();
              handleConfirm();
            }}
          >
            {t('reservation.signUp')}
          </ConfirmButton>
        </Row>
      </ModalContainer>
    );
  };

  const handleClickBook = () => {
    if (userInfo?.firstName && userInfo?.lastName) {
      navigateToBook();
    } else {
      uiStore?.universalModal.show({
        modalOption: {
          title: t('reservation.confirmSignUp'),
          style: {
            width: 600,
            height: 'auto',
            maxHeight: '100%',
          },
        },
        children: <ConfirmModalContent />,
      });
    }
  };

  const handleClickPhoto = (index: number) => {
    setInitialIndex(index);
    setIsGalleryViewerOpen(true);
  };

  const navigateToBook = () => {
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    // const guests = getUrlParam('guests');

    // guests 값이 없으면 listing의 minGuest 값을 사용
    let guests = getUrlParam('guests');
    if (!guests || guests === 'null' || guests === 'undefined') {
      guests = listingStore?.listing?.minGuest?.toString() || '2';
    }

    navigate(`/book/${listingId}?startdate=${startDate}&enddate=${endDate}&guests=${guests}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      let activeIndex: number | null = null;

      targetRefs.current.forEach((target, index) => {
        if (target) {
          const rect = target.getBoundingClientRect();

          // 요소가 화면 상단에서 100픽셀 이내에 도달했는지 확인
          if (rect.top <= 100 && rect.top > 0) {
            activeIndex = index;
          }
        }
      });
      if (activeIndex !== null) {
        // 활성화된 탭 상태 업데이트
        setActiveTab(activeIndex ?? 0);
      }
    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);
    if (listingId) {
      const listingIdNumber = Number(listingId);
      ServiceHelper.listingService
        ?.getListingById(Number(listingIdNumber), { locale: i18n.language })
        .then((res) => {
          listingStore?.setListing(res.data);
        });
    }

    if (validUser()) {
      ServiceHelper.myAccountService?.getMyProfile().then((res) => {
        setUserInfo(res.data);
      });
    }

    window.scrollTo({
      top: 0,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      listingStore?.unMount();
    };
  }, [listingId]);

  useEffect(() => {
    const listingIdNumber = Number(listingId);
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    // const guests = getUrlParam('guests');

    // guests 값이 없으면 listing의 minGuest 값을 사용
    let guests = getUrlParam('guests');
    if (!guests || guests === 'null' || guests === 'undefined') {
      guests = listingStore?.listing?.minGuest?.toString() || '2';

      // URL에 guests 파라미터 추가
      if (startDate && endDate) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('guests', guests);
        const pathname = window.location.pathname;
        window.history.replaceState(null, '', `${pathname}?${searchParams.toString()}`);
      }
    }

    if (startDate && endDate) {
      ServiceHelper.listingService
        ?.getPriceByListing(listingIdNumber, {
          startingDate: startDate ?? '',
          endingDate: endDate ?? '',
          adults: Number(guests) || 2,
        })
        .then((res) => {
          setListingPrice(res.data);
          setPriceType('actual');
        });
    } else {
      ServiceHelper.listingService
        ?.getLowestPriceByListing(listingIdNumber, { includeActualPrice: true })
        .then((res) => {
          setListingPrice(res.data);
          setPriceType('lowest');
        });
    }
  }, [location.search, filterStore?.isReadyLowestPrices]);

  useLayoutEffect(() => {
    const footer = document.querySelector('.footer') as HTMLElement;
    if (footer) {
      footer.style.display = 'block';
    }
  }, []);


  return (
    <PageContainer>
      <InnerWrapper className="page-container">
        <div style={{ zIndex: 0, position: 'relative' }}>
          <DetailPhotoBox
            images={listingStore?.listing?.images}
            onClick={(index) => {
              handleClickPhoto(index);
            }}
          />
          <div style={{ marginTop: isMobile ? 0 : 4 }}>
            <ListingSummary listing={listingStore?.listing} priceInfo={listingPrice} />
          </div>
          <div
            style={{
              marginTop: isMobile ? 0 : 50,
              position: 'sticky',
              top: isMobile ? 30 : 60,
              background: '#fff',
              zIndex: 10000,
              paddingTop: 10,
            }}
          >
            {isMobile ? (
              <MobileTab selected={activeTab} onChange={(id) => handleTabChange(id)} />
            ) : (
              <Tab selected={activeTab} onChange={(id) => handleTabChange(id)} />
            )}
          </div>
          {isMobile && (
            <div style={{ margin: '40px 20px' }}>
              <CheckPoint />
            </div>
          )}
          <BodyWrapper gap={50} alignItems="flex-start">
            <div style={{ width: '100%', maxWidth: '800px' }}>
              <div
                className="tab-item-0"
                ref={(el) => {
                  if (targetRefs.current) {
                    targetRefs.current[0] = el;
                  }
                }}
              >
                <Detail listing={listingStore?.listing} onClickImage={handleClickPhoto} />
              </div>
              <ContentWrapper>
                <div
                  className="tab-item-1"
                  ref={(el) => {
                    if (targetRefs.current) {
                      targetRefs.current[1] = el;
                    }
                  }}
                >
                  <Amenity />
                </div>
                <div
                  className="tab-item-2"
                  ref={(el) => {
                    if (targetRefs.current) {
                      targetRefs.current[2] = el;
                    }
                  }}
                >
                  <Plan
                    url={listingStore?.listing?.images?.find((image) => image.isPlan)?.url ?? ''}
                  />
                </div>
                <div>
                  <CancellationPolicy />
                </div>
                <div
                  className="tab-item-3"
                  ref={(el) => {
                    if (targetRefs.current) {
                      targetRefs.current[3] = el;
                    }
                  }}
                >
                  <Location listing={listingStore?.listing} />
                </div>
                <div
                  className="tab-item-4"
                  ref={(el) => {
                    if (targetRefs.current) {
                      targetRefs.current[4] = el;
                    }
                  }}
                >
                  <NearBy listingId={listingId} />
                </div>
                <div>
                  <RecommendListings listingId={listingId} />
                </div>
              </ContentWrapper>
            </div>
            <>
              {!isMobile && (
                <>
                  <div style={{ width: '350px', minWidth: '350px', position: 'sticky', top: 110 }}>
                    <CheckPoint />
                    <div style={{ marginTop: 24 }}>
                      <PriceBox
                        priceInfo={listingPrice}
                        onClick={() => {
                          handleClickBook();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </BodyWrapper>
        </div>
      </InnerWrapper>

      <>
        {isMobile && (
          <DetailReservationBoxWrapper>
            <DetailReservationBox
              listingPrice={listingPrice}
              listing={listingStore?.listing}
              priceType={priceType}
              onClick={handleClickBook}
            />
          </DetailReservationBoxWrapper>
        )}
      </>
      {isGalleryViewerOpen && (
        <>
          {isMobile ? (
            <GalleryViewerMobile
              listing={listingStore?.listing}
              initialIndex={initialIndex}
              onClose={() => setIsGalleryViewerOpen(false)}
            />
          ) : (
            <GalleryViewer
              listing={listingStore?.listing}
              initialIndex={initialIndex}
              onClose={() => setIsGalleryViewerOpen(false)}
            />
          )}
        </>
      )}
    </PageContainer>
  );
});

const InnerWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    padding: 0 !important;
  }
`;

const ContentWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    padding: 20px;
  }
`;

const BodyWrapper = styled(Row)`
  @media ${(props) => props.theme.media.mobile} {
    padding: 0px;
  }
`;

const DetailReservationBoxWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
`;

const ModalContainer = styled.div`
  padding-bottom: 40px;

  @media ${(props) => props.theme.media.mobile} {
    padding-top: 40px;
  }
`;

const ModalContent = styled.div`
  margin-bottom: 24px;
  text-align: center;
  white-space: pre-wrap;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 15px;
  color: #8e8e8e;
`;

const CancelButton = styled(Button)`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f6f6f6 !important;
  color: #000000 !important;
  align-items: center;
  justify-content: center;
  font-weight: 500 !important;
  width: 120px;
  height: 50px;
`;

const ConfirmButton = styled(Button)`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background-color: #4e433c !important;
  color: #f6f6f6 !important;
  align-items: center;
  justify-content: center;
  font-weight: 600 !important;
  width: 120px;
  height: 50px;
`;

export default DetailPage;
