import BTButton from 'components/input/BTButton';
import { CouponCard } from 'components/pages/myInfo/couponManagement/CouponCard';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import { UserCouponDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';
import styled from 'styled-components';

const CouponListModal = () => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { listingId } = useParams();

  const [coupons, setCoupons] = useState<UserCouponDto[]>([]);
  const [checkedCoupon, setCheckedCoupon] = useState<UserCouponDto>();
  const [isLoading, setIsLoading] = useState(true);

  const handleCouponClick = (couponCode: UserCouponDto) => {
    if (!couponCode) return;

    setCheckedCoupon((prev) => {
      if (prev?.code === couponCode.code) {
        return undefined;
      }
      return couponCode;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    ServiceHelper.myAccountService
      ?.getMyCoupons({
        listingId: listingId ? parseInt(listingId) : undefined,
        locale: i18n.language,
      })
      .then((res) => {
        setCoupons(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log('coupons', coupons);
  }, [coupons]);

  return (
    <div style={{ padding: '16px' }}>
      {isLoading ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : coupons.length > 0 ? (
        coupons.map((coupon) => (
          <div key={coupon?.code}>
            <CouponCard
              key={coupon?.code}
              coupon={coupon}
              checked={checkedCoupon?.code === coupon?.code}
              onClick={() => handleCouponClick(coupon)}
              selectable={true}
            />
          </div>
        ))
      ) : (
        <NoCouponMessage>{t('noCoupon')}</NoCouponMessage>
      )}
      <div style={{ width: '100%' }}>
        <Row width="100%" justifyContent="space-between" gap="10px">
          <BTButton type="outline" style={{ flex: 0.3 }} onClick={() => uiStore?.modal.close()}>
            {t('close')}
          </BTButton>
          {
            <BTButton
              style={{ flex: 0.7 }}
              onClick={() => {
                if (checkedCoupon) {
                  uiStore?.modal.confirm(checkedCoupon);
                }
              }}
              disabled={!checkedCoupon || coupons.length === 0}
            >
              {t('apply')}
            </BTButton>
          }
        </Row>
      </div>
    </div>
  );
};

const NoCouponMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 50px 0;
  color: #8e8e8e;
  font-size: 14px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

const LoadingSpinner = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  /* border-top: 3px solid #3498db; */
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
`;

export default CouponListModal;
