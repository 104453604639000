import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStoreWrapper } from 'stores/StoreHelper';
import TermModal from './termAndConditions/TermModal';
interface CheckboxItemProps {
  text: string;
  isRequired: boolean;
  checked: boolean;
  onToggle: () => void;
  link?: string;
  onLinkClick: (link: string) => void;
}

interface TermsAndConditionsProps {
  onTermsChange: (isValid: boolean) => void;
}

function TermsAndConditions({ onTermsChange }: TermsAndConditionsProps) {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();

  const termsData = [
    { text: t('signup.over19'), isRequired: true },
    { text: t('signup.termsAndConditions'), isRequired: true, link: 'PRIVACY_POLICY' },
    { text: t('signup.privacyPolicy'), isRequired: true, link: 'FACILITY_USAGE_POLICY' },
    { text: t('signup.marketing'), isRequired: false },
  ];
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(termsData.length).fill(false),
  );
  const [allChecked, setAllChecked] = useState(false);

  const handleItemToggle = (index: number) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
    setAllChecked(newCheckedItems.every((item) => item));
  };

  const handleLinkClick = (link?: string) => {
    uiStore?.modal.show({
      children: (
        <TermModal
          type={
            link as
              | 'PRIVACY_POLICY'
              | 'FACILITY_USAGE_POLICY'
              | 'THIRD_PARTY_PRIVACY_POLICY'
              | 'MARKETING_AGREEMENT'
              | 'RESERVATION'
              | 'CHECKIN'
              | 'USAGE'
          }
        />
      ),
      title: t('signup.termsAndConditions'),
      style: {
        width: '100%',
        maxWidth: '1000px',
        height: '100%',
        maxHeight: '80vh',
      },
    });
  };

  const handleAllToggle = () => {
    const newValue = !allChecked;
    setAllChecked(newValue);
    setCheckedItems(new Array(termsData.length).fill(newValue));
  };

  const CheckboxItem = ({
    text,
    isRequired,
    checked,
    onToggle,
    link,
    onLinkClick,
  }: CheckboxItemProps) => (
    <CheckboxWrapper>
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          checked={checked}
          onChange={onToggle}
          aria-label={`${text} ${isRequired ? t('signup.required') : t('signup.optional')}`}
        />
        <CheckboxLabel>
          [{isRequired ? t('signup.required') : t('signup.optional')}] {text}
        </CheckboxLabel>
      </CheckboxContainer>
      {link && (
        <div
          style={{
            color: '#1C51B6',
            fontSize: '13px',
            fontWeight: '400',
            cursor: 'pointer',
            marginRight: 10,
          }}
          onClick={() => {
            onLinkClick(link);
          }}
        >
          {t('view')}
        </div>
      )}
    </CheckboxWrapper>
  );

  useEffect(() => {
    const requiredTermsValid = checkedItems
      .filter((_, i) => termsData[i].isRequired)
      .every(Boolean);
    onTermsChange(requiredTermsValid);
  }, [checkedItems, onTermsChange]);

  return (
    <Container>
      <Header>
        <Title>{t('signup.termsAndConditions')}</Title>
      </Header>

      <Content>
        <AllCheckboxContainer>
          <CheckboxInput
            type="checkbox"
            checked={allChecked}
            onChange={handleAllToggle}
            aria-label={t('signup.allAgree')}
          />
          <AllCheckboxLabel>{t('signup.allAgree')}</AllCheckboxLabel>
        </AllCheckboxContainer>

        <CheckboxList>
          {termsData.map((item, index) => (
            <CheckboxItem
              key={index}
              text={item.text}
              isRequired={item.isRequired}
              checked={checkedItems[index]}
              onToggle={() => handleItemToggle(index)}
              link={item.link}
              onLinkClick={() => handleLinkClick(item.link)}
            />
          ))}
        </CheckboxList>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 12px;
  background: var(--Grayscale-0, #fff);
  max-width: 588px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const Header = styled.header`
  padding: 20px 0 10px 0;
  /* border-bottom: 1px solid var(--Grayscale-10, #f5f5f5); */
`;

const Title = styled.h2`
  font:
    600 19px Pretendard,
    sans-serif;
  letter-spacing: -0.05px;
  color: var(--Grayscale-90, #1d1d1d);
  margin: 0;
`;

const Content = styled.div`
  padding: 24px 0;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

const AllCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: rgba(247, 243, 239, 1);
  border-radius: 8px;
  margin-bottom: 16px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
`;

const CheckboxInput = styled.input`
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;

  &:checked {
    accent-color: #4e433c;
  }
`;

const CheckboxLabel = styled.label`
  font:
    400 14px Pretendard,
    sans-serif;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.04px;
`;

const AllCheckboxLabel = styled(CheckboxLabel)`
  font-weight: 600;
  font-size: 15px;
  color: var(--4-e-433-c, #4e433c);
`;

export default TermsAndConditions;
