import React from 'react';
import { countryCodes } from 'definitions/countryCodes';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// 선택된 국가 코드에서 국가 코드만 표시하는 옵션 추가 (showOnlyCode, displayValue, renderOption)
const CountryCode = ({
  value,
  onChange,
  error,
  isValid,
  defaultValue,
  onBlur,
  showOnlyCode = false, // 국가 코드만 표시할지 여부
  disabled = false,
}: {
  value?: any;
  onChange: (code: string) => void;
  error?: string;
  isValid?: boolean | null;
  onBlur?: () => void;
  defaultValue?: string;
  showOnlyCode?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const [newContryCode, setNewCountryCode] = useState([{ label: '', value: '' }]);

  const [localValue, setLocalValue] = useState<string>('');

  useEffect(() => {
    const newValue = countryCodes.map((item) => {
      return {
        label: `${item.name} (${item.countryCode})`,
        value: item.countryCode,
        displayValue: showOnlyCode ? item.countryCode : `${item.name} (${item.countryCode})`,
      };
    });

    setNewCountryCode(newValue);
  }, []);

  useEffect(() => {
    if (value) {
      const t = countryCodes.find((item) => item.countryCode === value);
      if (t) {
        setLocalValue(t.countryCode);
      }
    }
  }, [value]);

  const test = (option: any, value: any) => {
    return true;
  };

  useEffect(() => {
    if (defaultValue) {
      setLocalValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      defaultValue={defaultValue}
      value={localValue}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => test(option, value)}
      options={newContryCode}
      onChange={(e: any, value: any) => {
        const newValue = value?.value || '';
        setLocalValue(value?.displayValue || '');
        onChange(newValue);
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      onBlur={() => {
        if (!value && onBlur) {
          onBlur();
        }
      }}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Country code')}
          defaultValue={defaultValue}
          error={!!error || (!value && params.inputProps['aria-activedescendant'] === undefined)}
          helperText={
            error ||
            (!value && params.inputProps['aria-activedescendant'] === undefined
              ? t('This field is required')
              : '')
          }
          required
          onBlur={onBlur}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor:
                  !value && params.inputProps['aria-activedescendant'] === undefined
                    ? '#F35064'
                    : isValid === true
                      ? '#4CAF50'
                      : isValid === false
                        ? '#F35064'
                        : 'rgba(0, 0, 0, 0.23)',
              },
            },
          }}
        />
      )}
    />
  );
};

<Select
  fullWidth
  onChange={(e: any) => {
    // setModel("profile", {
    //   ...model?.profile,
    //   countryCode: e.target.value,
    // });
  }}
>
  {[...countryCodes]
    .sort((a: any, b: any) => a.name?.localeCompare(b.name))
    .map((item: any, i: number) => {
      return (
        <MenuItem key={i} value={item.No}>
          {item.EngName} ({item.No})
        </MenuItem>
      );
    })}
</Select>;
export default CountryCode;
