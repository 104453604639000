import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, useModelStateContext, InputField, useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import BTButton from 'components/input/BTButton';
import CountryCode from 'components/commons/CountryCode';
import { SocialSignUpDto } from 'services/data-contracts';
import { validationHelper } from 'libs/helper/validationHelper';
import TermsAndConditions from 'components/commons/TermsAndConditions';
import { useSearchParams } from 'react-router-dom';
import useSocialSignUp from 'hooks/useSocialSignUp';
import { AlertMessage } from './AlertMessage';
import BirthdayField from 'components/input/BirthdayField';

export const SignupForm = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { handleSignup, loadSocialData } = useSocialSignUp();
  const { isMobile } = useMediaContext();
  const { setState, stateModel } = useModelStateContext<SocialSignUpDto>();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [termsValid, setTermsValid] = useState<boolean>(false);
  const [referralEmailError, setReferralEmailError] = useState<string>('');

  // 기존 개별 에러 상태를 통합하여 처리
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    birthday: '',
    referralEmail: '',
  });

  // 개별 필드 유효성 검사
  const validateField = (name: string, value: string) => {
    let isValid = false;
    let errorMessage = '';
    const trimmedValue = value.trim(); // 앞뒤 공백 제거

    switch (name) {
      case 'firstName':
        if (!trimmedValue) {
          errorMessage = t('guest_info_required');
        } else {
          isValid = validationHelper.firstName(value);
          if (!isValid) {
            errorMessage = t('no_special_chars_spaces');
          }
        }
        break;

      case 'lastName':
        if (!trimmedValue) {
          errorMessage = t('guest_info_required');
        } else {
          isValid = validationHelper.lastName(value);
          if (!isValid) {
            errorMessage = t('no_special_chars_spaces');
          }
        }
        break;

      case 'countryCode':
        isValid = validationHelper.countryCode(value);
        if (!isValid) {
          errorMessage = t('select_country_code');
        }
        break;

      case 'phoneNumber':
        isValid = validationHelper.phoneNumber(value);
        if (!isValid) {
          errorMessage = value ? t('phone_length_error') : t('guest_info_required');
        }
        break;

      case 'birthday':
        isValid = validationHelper.birthday(value);
        if (!isValid) {
          errorMessage = value ? t('signup.invalidBirthdayFormat') : t('guest_info_required');
        }
        break;

      case 'referralEmail':
        if (trimmedValue) {
          // 공백만 있는 경우는 빈 값으로 처리
          isValid = validationHelper.referrerEmail(trimmedValue);
          if (!isValid) {
            errorMessage = t('signup.invalidEmailFormat');
          }
        } else {
          isValid = true; // 선택적 필드이므로 빈 값 허용
        }
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));

    return isValid;
  };

  const handleBlur = (name: string, value: string) => {
    validateField(name, value);
  };

  // 전체 필드 유효성 검사
  const validate = () => {
    const firstName = validationHelper.firstName(stateModel?.profile?.firstName);
    const lastName = validationHelper.lastName(stateModel?.profile?.lastName);
    const email = validationHelper.email(stateModel?.username);
    const countryCode = validationHelper.countryCode(stateModel?.profile?.countryCode);
    const phoneNumber = validationHelper.phoneNumber(stateModel?.profile?.phoneNumber);
    const referrerEmail =
      !stateModel?.referrerEmail || validationHelper.referrerEmail(stateModel?.referrerEmail);
    const birthday = validationHelper.birthday(stateModel?.profile?.birthday);

    setIsValid(
      firstName &&
        lastName &&
        email &&
        countryCode &&
        phoneNumber &&
        referrerEmail &&
        birthday &&
        termsValid,
    );
  };

  const handleClickSignup = async () => {
    const result = await handleSignup(stateModel as SocialSignUpDto);
    if (result) {
      setIsValid(true);
    }
  };

  useEffect(() => {
    loadSocialData();
  }, [searchParams]);

  useEffect(() => {
    validate();
  }, [stateModel, termsValid]);

  return (
    <FormContainer role="main">
      <FormTitle style={{ whiteSpace: 'pre-line' }}>{t('signup.title')}</FormTitle>

      <FormSection>
        <InputFieldGroup>
          <FormLabel>
            {t('signup.name')}
            <RequiredMark>*</RequiredMark>
          </FormLabel>
          <Row gap={8} alignItems="flex-start">
            {i18n.language === 'en' ? (
              <>
                <InputField
                  placeholder={t('signup.firstName')}
                  id="firstName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setState('profile', { ...stateModel?.profile, firstName: trimmedValue });
                    handleBlur('firstName', trimmedValue);
                  }}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text').trim();
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                />
                <InputField
                  placeholder={t('signup.lastName')}
                  id="lastName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setState('profile', { ...stateModel?.profile, lastName: trimmedValue });
                    handleBlur('lastName', trimmedValue);
                  }}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text').trim();
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                />
              </>
            ) : (
              <>
                <InputField
                  placeholder={t('signup.lastName')}
                  id="lastName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                  onBlur={(e) => handleBlur('lastName', e.target.value)}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                />
                <InputField
                  placeholder={t('signup.firstName')}
                  id="firstName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                  onBlur={(e) => handleBlur('firstName', e.target.value)}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                />
              </>
            )}
          </Row>
        </InputFieldGroup>

        <FormLabel>
          {t('signup.email')}
          <RequiredMark>*</RequiredMark>
        </FormLabel>
        <InputField
          placeholder={t('signup.email')}
          type="email"
          id="email"
          disabled={true}
          value={stateModel?.username}
          onChange={(value: string) => {
            setState('username', value);
          }}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            const value = e.clipboardData.getData('text');
            setState('username', value);
          }}
        />

        <PhoneSection>
          <FormLabel>
            {t('signup.phone')}
            <RequiredMark>*</RequiredMark>
          </FormLabel>
          <Row gap={5} alignItems="flex-start">
            <CountryCodeWrapper>
              <CountryCode
                onChange={(value: string) => {
                  setState('profile', { ...stateModel?.profile, countryCode: value });
                }}
                showOnlyCode={true}
                onBlur={() => handleBlur('countryCode', stateModel?.profile?.countryCode || '')}
                error={errors.countryCode}
              />
            </CountryCodeWrapper>
            <PhoneFieldWrapper>
              <InputField
                id="contact"
                type={isMobile ? 'tel' : 'number'}
                placeholder={t('signup.phoneNumber')}
                onChange={(value: string) => {
                  setState('profile', { ...stateModel?.profile, phoneNumber: value });
                }}
                onBlur={(e) => handleBlur('phoneNumber', e.target.value)}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber || ''}
                onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                  const value = e.clipboardData.getData('text');
                  setState('profile', { ...stateModel?.profile, phoneNumber: value });
                }}
              />
            </PhoneFieldWrapper>
          </Row>
          <AlertMessage className="alert-message" message={t('signup.phoneNumberAlert')} />
        </PhoneSection>
      </FormSection>

      <AdditionalSection>
        <FormLabel>
          {t('signup.referralEmail')}
          <RequiredMark>*</RequiredMark>
        </FormLabel>
        <InputField
          // label={t('signup.referralEmail')}
          placeholder={t('signup.referralEmail')}
          type="email"
          id="referral"
          error={!!errors.referralEmail}
          onChange={(value: string) => {
            setState('referrerEmail', value);
            validateField('referralEmail', value);
          }}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            const value = e.clipboardData.getData('text');
            setState('referrerEmail', value);
            validateField('referralEmail', value);
          }}
        />
        {errors.referralEmail ? (
          <StyledAlertMessage
            className="alert-message"
            message={errors.referralEmail}
            isError={true}
          />
        ) : (
          <AlertMessage className="alert-message" message={t('signup.referralEmailAlert')} />
        )}

        <BirthdayContainer>
          <BirthdayInfo>
            <BirthdayIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/4f6d23437e06dab8e81d35b50055e958c648d1ab5f3741f3ff50ca57340624dd?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt="생일 아이콘"
            />
            <BirthdayText style={{ whiteSpace: 'pre-line' }}>
              {t('signup.birthdayAlert')}
            </BirthdayText>
          </BirthdayInfo>
          <BirthdayField
            label={t('signup.birthday')}
            required={true}
            placeholder={t('signup.birthday')}
            value={stateModel?.profile?.birthday || ''}
            onChange={(value: string) => {
              setState('profile', { ...stateModel?.profile, birthday: value });
              validateField('birthday', value);
            }}
            error={errors.birthday}
            onBlur={() => {
              validateField('birthday', stateModel?.profile?.birthday || '');
            }}
            helperText={errors.birthday || ''}
          />
        </BirthdayContainer>

        <TermsAndConditions
          onTermsChange={(isValid) => {
            setTermsValid(isValid);
          }}
        />
      </AdditionalSection>
      <BTButton
        disabled={!isValid}
        onClick={() => {
          handleClickSignup();
        }}
      >
        {t('signup.signup')}
      </BTButton>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  max-width: 588px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  margin: 56px auto;
  margin-bottom: 100px;

  @media ${(props) => props.theme.media.mobile} {
    padding: 0 20px;
    margin: 34px auto;
  }
`;

const FormTitle = styled.h1`
  color: var(--Grayscale-80, #2d2d2d);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.06px;
`;

const FormSection = styled.section`
  margin-top: 56px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const PhoneSection = styled.div`
  margin-top: 48px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const InputFieldGroup = styled.div`
  margin-bottom: 45px;
`;

const FormLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: '#1d1d1d';
`;

const RequiredMark = styled.span`
  color: #f35064;
  margin-left: 4px;
`;

const AdditionalSection = styled.section`
  margin-top: 56px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const CountryCodeWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
  & > div {
    flex: 1;
    width: 50%;
  }
  width: 100%;
  & .MuiAutocomplete-root {
    width: 100% !important;
  }
`;

const PhoneFieldWrapper = styled.div`
  width: 100%;
  & > div {
    flex: 1;
    width: 50%;
  }
`;

const StyledAlertMessage = styled(AlertMessage)`
  &.alert-message {
    color: ${(props) => (props.isError ? '#d32f2f' : '#717171')};
    font-size: 12px;
    margin-top: 8px;
  }
`;

const BirthdayContainer = styled.div`
  margin-top: 48px;
`;

const BirthdayInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 48px 0;
`;

const BirthdayIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100px;
`;

const BirthdayText = styled.p`
  margin-top: 16px;
  font-size: 17px;
  line-height: 26px;
  color: var(--4-e-433-c, #4e433c);
`;
