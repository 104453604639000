import { convertStringToDate } from 'libs/helper/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormat, Row, useMediaContext } from 'saladsoft-primitive';
import { ListingDto, ListingPriceDto, ReservationDto } from 'services/data-contracts';
import styled from 'styled-components';
import { format } from 'react-string-format';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { Header } from 'resources/styles/CommonStyles';
import moment from 'moment';
function ReservationSummary({
  reservationInfo,
  title,
}: {
  reservationInfo?: ReservationDto;
  title?: string;
}) {
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();
  const [stayDuration, setStayDuration] = useState<string>('');
  const [listing, setListing] = useState<ListingDto | null>(null);

  useEffect(() => {
    if (reservationInfo) {
      ServiceHelper?.listingService
        ?.getListingById(reservationInfo?.listingId ?? 0, { locale: i18n.language })
        .then((res) => {
          setListing(res.data);
        });
      const startDate = new Date(reservationInfo?.arrivalDate ?? '');
      const endDate = new Date(reservationInfo?.departureDate ?? '');
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const formattedStartDate = convertStringToDate(reservationInfo?.arrivalDate ?? '');
      const formattedEndDate = convertStringToDate(reservationInfo?.departureDate ?? '');

      setStayDuration(
        `${formattedStartDate} ~ ${formattedEndDate} (${format(t('stayDurationFormat'), diffDays)})`,
      );
    }
  }, [reservationInfo]);

  return (
    <BasicInfo>
      <Header style={{ marginBottom: isMobile ? '20px' : '94px' }}>
        {title ? title : t('reservation.history')}
      </Header>
      <Row justifyContent="space-between" alignItems="center" width="100%">
        <div
          style={{ fontSize: '13px', fontWeight: '600', letterSpacing: '-0.04px', color: '555' }}
        >
          {moment(reservationInfo?.reservationDate).format('YYYY.MM.DD')}
        </div>
        <div style={{ fontSize: '13px', color: '#8E8E8E' }}>
          <Row>
            <div>{t('reservation.number')} :</div>
            <div style={{ marginLeft: 3 }}>{reservationInfo?.hostawayReservationId}</div>
          </Row>
        </div>
      </Row>
      <div style={{ borderBottom: '2px solid #555', margin: '20px 0' }} />
      <ListingInfo>
        <Img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/05b0d70961fd2fe146d0d53418302597163a653076e1bba232ebf606b2c04b72?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
        />
        <Div>
          <Div2>
            <Div3>
              <Div4>{listing?.name}</Div4>
              <Div5>{listing?.address}</Div5>
            </Div3>
          </Div2>
        </Div>
      </ListingInfo>
      <BookInfoStyle>
        <Div13>
          <Div14>
            <Div15>{t('date')}</Div15>
            <Div16>
              <Div17>{stayDuration}</Div17>
            </Div16>
          </Div14>
          <Div18>
            <Div19>{t('guest')}</Div19>
            <Div20>{format(t('guestsFormat'), reservationInfo?.adults ?? 2)}</Div20>
          </Div18>
          <div style={{ borderBottom: '1px solid #E4E4E4', margin: '20px 0' }} />

          <Div21>
            <Div22>{t('reservation.totalPayment')}</Div22>
            <Div23>
              <Row fontSize="19px" fontWeight="600" letterSpacing="-0.04px" alignItems="center">
                <NumberFormat value={reservationInfo?.totalAmount ?? 0} />
                <Div27>{t('won')}</Div27>
              </Row>
            </Div23>
          </Div21>
        </Div13>
      </BookInfoStyle>
    </BasicInfo>
  );
}

const BasicInfo = styled.div`
  display: flex;
  max-width: 792px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
`;

const ListingInfo = styled.div`
  display: flex;
  gap: 16px;
  justify-content: start;
  /* flex-wrap: wrap; */
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100px;
  border-radius: 4px;
  align-self: start;
`;

const Div = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div3 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div4 = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const Div5 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const Div6 = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: var(--Grayscale-70, #555);
  font-weight: 400;
  justify-content: start;
  flex-wrap: wrap;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 12px;
  }
`;

const Div7 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: auto 0;
`;

const Div8 = styled.div`
  color: var(--Color, #1c51b6);
  align-self: stretch;
  margin: auto 0;
`;

const Div9 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const Div10 = styled.div`
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

const Img2 = styled.img`
  aspect-ratio: 0.08;
  object-fit: contain;
  object-position: center;
  width: 1px;
  stroke-width: 1px;
  stroke: var(--Grayscale-20, #e4e4e4);
  align-self: stretch;
  margin: auto 0;
`;

const Div11 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 2px;
  white-space: nowrap;
  letter-spacing: -0.03px;
  justify-content: start;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
`;

const Div12 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const BookInfoStyle = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div13 = styled.div`
  border-radius: 0px 0px 12px 12px;
  background-color: rgba(251, 249, 245, 1);
  /* border-color: rgba(193, 186, 182, 1); */
  background: var(--Grayscale-5, #f8f8f8);
  border-top-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 20px 16px;
  border-top: 1px solid #c1bab6;

  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div14 = styled.div`
  display: flex;
  min-height: 10px;
  width: 100%;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  letter-spacing: -0.04px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    align-items: center;
  }
`;

const Div15 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
`;

const Div16 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: auto 0;
`;

const Div17 = styled.div`
  width: 100%;
`;

const Div18 = styled.div`
  display: flex;
  margin-top: 10px;
  min-height: 10px;
  width: 100%;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  white-space: nowrap;
  letter-spacing: -0.04px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div19 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);

  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
`;

const Div20 = styled.div`
  align-self: stretch;
  gap: 8px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 400;
  text-align: center;
  width: 21px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img4 = styled.img`
  /* aspect-ratio: 1000; */
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: var(--Grayscale-20, #e4e4e4);
  height: 0;
  align-self: stretch;
`;

const Div21 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    max-width: 100%;
    align-items: center;
  }
`;

const Div22 = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const Div23 = styled.div`
  display: flex;
  min-height: 34px;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 242px;
`;

const Div24 = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const Div25 = styled.div`
  display: flex;
  /* margin-top: 12px; */
  align-items: center;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  white-space: nowrap;
  justify-content: start;
  @media (max-width: 991px) {
    white-space: initial;
    margin-top: 0;
  }
`;

const Div26 = styled.div`
  font-size: 19px;
  letter-spacing: -0.05px;
  align-self: stretch;
  margin: auto 0;
  width: 100%;
`;

const Div27 = styled.div`
  font-size: 13px;
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

export default ReservationSummary;
