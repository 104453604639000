import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { MembershipCard } from './MembershipCard';
import { LevelIndicator } from './LevelIndicator';
import { MembershipLevelProps } from './types';
import ClassicCard from './cards/Classic';
import { useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from 'providers/UserInfoProvider';
import { getFullName } from 'libs/helper/utils';

export const MembershipLevel: React.FC<MembershipLevelProps> = () => {
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();

  const { loginStatus, myAccount } = useUserInfo();

  const userName = getFullName(myAccount?.profile);
  const currentLevel = myAccount?.membershipLevel || '';

  const membershipData = [
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/96a7a5e60ec3b42abfc89f115e075e92204eeaa6d6b2aaf7b64cf72bba5edcc5?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      level: 'PLATINUM',
      requirement: t('platinumRequirement', { stayCount: 7, nightCount: 10 }),
      percentage: 9,
    },
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/476416cdf1cd0edd20f0117e2e72213c44b1c903c72c33ddd800790aff7b5904?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      level: 'GOLD',
      requirement: t('goldRequirement', { stayCount: 3, nightCount: 4 }),
      percentage: 5,
    },
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/d85d30e2ae33224a0cc9952dff2900fdd4546634614f90418fc7dccd30a851f8?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      level: 'SILVER',
      requirement: t('silverRequirement', { stayCount: 1, nightCount: 2 }),
      percentage: 3,
    },
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/6f19f9017dfdcda884d4b7c9e93bb70c2555db5fcb82bfcc9871c135b414108e?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      level: 'CLASSIC',
      requirement: t('classicRequirement'),
      percentage: 1,
    },
  ];

  // Classic 카드 컴포넌트 내 회원등급 표시 (ex: Platinum)
  const cardMembershipLevel =
    currentLevel.charAt(0).toUpperCase() + currentLevel.slice(1).toLocaleLowerCase();

  useEffect(() => {
    if (loginStatus && myAccount) {
      console.log(currentLevel);
    }
  }, [loginStatus, myAccount]);

  return (
    <PageWrapper>
      <PageContent>
        <ClassicCard
          memberName={userName}
          membershipLevel={cardMembershipLevel}
          profileImage={`/images/${currentLevel}.png`}
        />
        <LevelIndicator currentLevel={currentLevel || ''} />

        <LevelInfoSection>
          <SectionTitle>{t('membershipLevelCriteria')}</SectionTitle>
          <CardContainer>
            {membershipData?.map((membership) => (
              <MembershipCard key={membership.level} {...membership} />
            ))}
          </CardContainer>
        </LevelInfoSection>

        <FooterNotes>
          <Note>{t('membershipAutomaticApplication')}</Note>
          <Note>{t('membershipAnnualEvaluation')}</Note>
        </FooterNotes>
      </PageContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.main`
  background-color: #fff;
  display: flex;
  max-width: 825px;
  flex-direction: column;
  width: 100%;

  @media ${(props) => props.theme.media.mobile} {
    background: var(--Grayscale-5, #f8f8f8);
  }
`;

const PageContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LevelInfoSection = styled.section`
  display: flex;
  margin-top: 80px;
  width: 100%;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.05px;
  font:
    600 19px Pretendard,
    sans-serif;
`;

const CardContainer = styled.div`
  border-radius: 8px;
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

const FooterNotes = styled.footer`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  color: var(--Grayscale-50, #8e8e8e);
  letter-spacing: -0.03px;
  line-height: 150%;
  font:
    400 12px Pretendard,
    sans-serif;
`;

const Note = styled.p`
  margin: 0;
  line-height: 150%;
`;
