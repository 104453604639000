import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  ListingDto,
  ListingPriceDto,
  NonMemberReservationDto,
  PaymentDto,
  ReservationDto,
} from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { convertStringToDate, ConvertStringToParenthesisDate } from 'libs/helper/utils';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { useUserInfo } from 'providers/UserInfoProvider';
const ReservationDetails = ({
  reservationInfo,
  model,
  paymentInfo,
}: {
  reservationInfo?: ReservationDto;
  model?: NonMemberReservationDto | any;
  paymentInfo?: PaymentDto;
}) => {
  const { myAccount } = useUserInfo();
  const { t } = useTranslation();
  const [listing, setListing] = useState<ListingDto | null>(null);
  const [listingPrice, setListingPrice] = useState<PaymentDto | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const getStatus = () => {
    switch (reservationInfo?.status) {
      case 'NEW':
        return t('reservation.status.new');
      case 'CONFIRMED':
        return t('reservation.status.confirmed');
      case 'FAILED':
        return t('reservation.status.failed');
      case 'CANCELLED':
        return t('reservation.status.cancelled');
      // case 'COMPLETED':
      //   return t('reservation.status.completed');
      default:
        return t('reservation.status.unknown');
    }
  };

  // 취소된 예약 및 지난 예약(날짜 기반) 여부 확인
  const isCancelled = () => {
    return reservationInfo?.status === 'CANCELLED';
  };

  const isPast = () => {
    const today = new Date();
    const departureDate = reservationInfo?.departureDate
      ? new Date(reservationInfo.departureDate)
      : null;

    return departureDate && departureDate < today;
  };

  // 예약 상태에 따른 헤더 메시지 결정 함수
  const getHeaderMessage = () => {
    if (isCancelled()) {
      return t('reservation.cancelledInfoMessage');
    } else if (isPast()) {
      return t('reservation.pastInfoMessage');
    } else {
      return t('reservation.infoMessage');
    }
  };

  const getSchedule = () => {
    const nights = moment(reservationInfo?.departureDate).diff(
      moment(reservationInfo?.arrivalDate),
      'days',
    );
    return `${convertStringToDate(reservationInfo?.arrivalDate as string)} ~ ${convertStringToDate(reservationInfo?.departureDate as string)} (${nights}${t('reservation.nights')})`;
  };
  const getName = () => {
    if (reservationInfo?.guestCountryCode === '+82') {
      return `${reservationInfo?.guestLastName} ${reservationInfo?.guestFirstName}`;
    }
    return `${reservationInfo?.guestFirstName} ${reservationInfo?.guestLastName}`;
  };

  const PaymentInfoRow = ({ label, value }: { label?: string; value?: string }) => (
    <PaymentRow>
      <PaymentLabel>{label}</PaymentLabel>
      <PaymentValue>{value}</PaymentValue>
    </PaymentRow>
  );

  const PriceInfoRow = ({
    label,
    value,
    highlight = false,
  }: {
    label?: string;
    value?: string;
    highlight?: boolean;
  }) => (
    <PriceRow>
      <PriceLabel>{label}</PriceLabel>
      <PriceAmount highlight={highlight}>{value}</PriceAmount>
    </PriceRow>
  );

  useEffect(() => {
    if (reservationInfo) {
      ServiceHelper.listingService
        ?.getListingById(reservationInfo.listingId as number, {
          locale: i18n.language,
        })
        .then((res) => {
          if (res.status === 200) {
            setListing(res.data);
          } else {
            toast.error('예약정보 취득에 실패하였습니다.');
          }
        });
    }
  }, [reservationInfo]);

  useEffect(() => {
    if (model) {
      ServiceHelper.nonReservationService?.getPaymentForNonMember(model).then((res) => {
        setListingPrice(res.data);
      });
    }
  }, [model]);

  useEffect(() => {
    if (reservationInfo?.id) {
      ServiceHelper.myReservationService
        ?.getPaymentByReservation(reservationInfo.id as number)
        .then((res) => {
          setListingPrice(res.data);
        });
    }
    console.log('reservationInfo', reservationInfo);
  }, [reservationInfo]);

  return (
    <Container>
      <Header>
        {getName()} {t('reservation.guestTitle')}
        <br />
        {getHeaderMessage()}
      </Header>
      <ContentWrapper>
        <ReservationHeader>
          <ReservationNumber>
            {t('reservation.number')}: {reservationInfo?.hostawayReservationId}
          </ReservationNumber>
          <CopyButton
            onClick={() => {
              navigator.clipboard.writeText(
                reservationInfo?.hostawayReservationId?.toString() || '',
              );
              toast.success(t('copyReservationNumber'));
            }}
          >
            <CopyIcon
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4686e36f9c2230e382b9c402d024126b79b1643b34cf4dabc1c5d8ed7802fb91?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              alt=""
            />
            <ButtonText>{t('reservation.copyNumber')}</ButtonText>
          </CopyButton>
        </ReservationHeader>
        <ReservationContent>
          <ContentSection>
            <BackgroundLogo
              xmlns="http://www.w3.org/2000/svg"
              width="110"
              height="110"
              viewBox="0 0 110 110"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.8738 19.3504L93.5468 18.9234C84.7263 7.40424 70.8739 0.00142908 55.2983 0.00142908C40.4133 0.00142908 27.1052 6.76096 18.2545 17.4131L17.896 17.8446L17.4646 18.2031C6.78888 27.0726 0.00142908 40.449 0.00142908 55.4235C0.00142908 70.6048 6.97805 84.1444 17.9098 93.0093L18.3019 93.3273L18.6361 93.7056C27.475 103.712 40.3646 110.002 54.724 110.002C68.4437 110.002 80.8184 104.261 89.6037 95.0237L89.9677 94.641L90.3923 94.327C102.295 85.5209 110.001 71.3771 110.001 55.4235C110.001 41.2745 103.943 28.5556 94.2707 19.7133L93.8738 19.3504ZM23.6981 26.2203L23.4062 24.1163C26.0361 25.0585 33.4075 24.9442 36.7703 24.8921L36.975 24.8889L37.9965 19.75C41.989 20.528 50.2794 20.5167 54.7752 20.5105L54.9222 20.5103V20.5096L55.3594 20.5098L55.7966 20.5096V20.5103L55.9436 20.5105C60.4393 20.5167 68.7297 20.528 72.7223 19.75L74.4733 24.8888L74.7777 24.8935C78.1438 24.9463 84.7088 25.0491 87.3125 24.1163L87.0207 26.2203C84.1003 27.5024 74.0356 29.7632 55.7966 29.8335V29.8352C55.6503 29.8352 55.5046 29.8351 55.3594 29.8348C55.2141 29.8351 55.0684 29.8352 54.9222 29.8352V29.8335C36.6831 29.7632 26.6184 27.5024 23.6981 26.2203ZM55.2376 31.8735C44.6229 51.1927 29.1142 54.7516 22.7734 54.116C28.0999 58.6921 35.2647 60.8401 38.1182 60.5223L55.2383 56.3407L72.3584 60.5223C75.2119 60.8401 82.3767 58.6921 87.7031 54.116C81.3624 54.7516 65.8537 51.1927 55.239 31.8735V31.8711L55.2383 31.8723L55.2376 31.8711V31.8735ZM21.3594 79.3962C31.1656 79.1848 50.2753 75.6753 55.4005 61.0146V60.9141L55.418 60.9645L55.4354 60.9141V61.0146C60.5606 75.6753 79.6703 79.1848 89.4766 79.3962V83.2349C73.7677 88.06 60.8183 89.3506 55.418 89.2478C50.0176 89.3506 37.0682 88.06 21.3594 83.2349V79.3962Z"
                fill="#1F1E1D"
                fillOpacity="0.05"
              />
            </BackgroundLogo>
            <HostInfo>
              <HostDetails>
                <HostName>{reservationInfo?.listingName}</HostName>
                <HostAddress>{listing?.address}</HostAddress>
              </HostDetails>
              <LocationIcon
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e359ea65bc82669e3c2d5fa9c1c6884214fec8973c5fd477586704d49e01a34c?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                alt=""
              />
            </HostInfo>
            {/* {reservationInfo?.status === 'NEW' && ( */}
            {!isCancelled() && !isPast() && (
              <StayInfo>
                <CheckInInfo>
                  <CheckInLabel>{t('reservation.checkIn')}</CheckInLabel>
                  <CheckInTime>
                    {ConvertStringToParenthesisDate(reservationInfo?.arrivalDate || '', true)}
                    <br />
                    {listing?.checkInTime}:00
                  </CheckInTime>
                </CheckInInfo>
                <StayDuration>
                  {moment(reservationInfo?.departureDate).diff(
                    moment(reservationInfo?.arrivalDate),
                    'days',
                  )}
                  {t('reservation.nights')}
                </StayDuration>
                <CheckOutInfo>
                  <CheckOutLabel>{t('reservation.checkOut')}</CheckOutLabel>
                  <CheckOutTime>
                    {ConvertStringToParenthesisDate(reservationInfo?.departureDate || '', true)}
                    <br />
                    {listing?.checkOutTime}:00
                  </CheckOutTime>
                </CheckOutInfo>
              </StayInfo>
            )}
            {/* )} */}
            <Divider
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/527f6e077d7badae870ed4ac2d0e658e2dd7867554d8d37926c00c105b6549d3?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              alt=""
            />
            <AddressSection>
              <AddressLabel>{t('reservation.address')}</AddressLabel>
              <AddressContent>
                <AddressText>{listing?.address}</AddressText>
                <CopyAddressButton
                  onClick={() => {
                    navigator.clipboard.writeText(listing?.address || '');
                    toast.success(t('copyAddress'));
                  }}
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5ba37ebd0b68134335abb0539612a8557848dc3f45500ad6165def3c6ba46fd?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                    alt=""
                  />
                  <CopyText>{t('reservation.copyAddress')}</CopyText>
                </CopyAddressButton>
              </AddressContent>
            </AddressSection>
            <InfoRow>
              <InfoLabel>{t('reservation.schedule')}</InfoLabel>
              <InfoValue>{getSchedule()}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>{t('reservation.guests')}</InfoLabel>
              <InfoValue>
                {reservationInfo?.adults}
                {t('reservation.peopleCount')}
              </InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>{t('reservation.status.label')}</InfoLabel>
              <StatusValue>{getStatus()}</StatusValue>
            </InfoRow>
            <Divider
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/527f6e077d7badae870ed4ac2d0e658e2dd7867554d8d37926c00c105b6549d3?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              alt=""
            />
            <SectionTitle>{t('reservation.paymentInfo')}</SectionTitle>
            <PaymentInfoRow
              label={t('reservation.paymentMethod')}
              value={listingPrice?.cardNumber ? t('card') : listingPrice?.method}
            />
            <PaymentInfoRow
              label={t('reservation.approvalDate')}
              value={moment(listingPrice?.approvedDate).format('YYYY-MM-DD HH:mm:ss')}
            />
            <PaymentInfoRow label={t('reservation.cardNumber')} value={listingPrice?.cardNumber} />
            <PaymentInfoRow
              label={t('reservation.installment')}
              value={
                listingPrice?.cardInstallmentPlanMonths === 0
                  ? t('reservation.lumpSum')
                  : `${listingPrice?.cardInstallmentPlanMonths}${t('reservation.months')}`
              }
            />
            <Divider
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/527f6e077d7badae870ed4ac2d0e658e2dd7867554d8d37926c00c105b6549d3?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              alt=""
            />
            <PriceSection>
              <PriceSectionHeader>
                <PriceSectionTitle>{t('reservation.paymentDetails')}</PriceSectionTitle>
                <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                  <ExpandText>
                    {isExpanded ? t('reservation.collapse') : t('reservation.expand')}
                  </ExpandText>
                  <ExpandIcon
                    isExpanded={isExpanded}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 13L15 7.5"
                      stroke="#555555"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                    />
                  </ExpandIcon>
                </ExpandButton>
              </PriceSectionHeader>
              <PriceInfoRow
                label={t('reservation.accommodationFee')}
                value={`${reservationInfo?.basePrice?.toLocaleString()}${t('KRW')}`}
              />
              {isExpanded && (
                <>
                  {/* 객실 관리비 */}
                  <PriceInfoRow
                    label={t('reservation.cleaningFee')}
                    value={`${reservationInfo?.cleaningFee?.toLocaleString()}${t('KRW')}`}
                  />
                  {/* 쿠폰 할인 */}
                  <DiscountRow>
                    <DiscountLabel>
                      <span>{t('reservation.coupon')}</span>
                    </DiscountLabel>
                    <DiscountAmount>
                      {reservationInfo?.couponDiscountAmount
                        ? `-${reservationInfo?.couponDiscountAmount?.toLocaleString()}${t('KRW')}`
                        : `0${t('won')}`}
                    </DiscountAmount>
                  </DiscountRow>
                  {/* 바우처 할인 */}
                  <DiscountRow>
                    <DiscountLabel>
                      <span>{t('reservation.voucher')}</span>
                    </DiscountLabel>
                    <DiscountAmount>
                      {reservationInfo?.voucherDiscountAmount
                        ? `-${reservationInfo?.voucherDiscountAmount?.toLocaleString()}${t('KRW')}`
                        : `0${t('won')}`}
                    </DiscountAmount>
                  </DiscountRow>
                  {/* 포인트 할인 */}
                  {/* <PriceInfoRow
                    label={t('reservation.points')}
                    value={`-${reservationInfo?.usePoint?.toLocaleString()}${t('KRW')}`}
                  /> */}
                  <DiscountRow>
                    <DiscountLabel>
                      <span>{t('reservation.points')}</span>
                    </DiscountLabel>
                    <DiscountAmount>
                      {reservationInfo?.usePoint
                        ? `-${reservationInfo?.usePoint?.toLocaleString()}${t('KRW')}`
                        : `0${t('won')}`}
                    </DiscountAmount>
                  </DiscountRow>
                </>
              )}
            </PriceSection>
            <Divider
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/527f6e077d7badae870ed4ac2d0e658e2dd7867554d8d37926c00c105b6549d3?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              alt=""
            />
            {paymentInfo?.status === 'CANCELLED' ? (
              <>
                <PriceSectionHeader>
                  <PriceSectionTitle>{t('reservation.cancelInformation')}</PriceSectionTitle>
                </PriceSectionHeader>
                <PriceInfoRow
                  label={t('reservation.refundAmount')}
                  value={`${paymentInfo?.cancelAmount?.toLocaleString()}${t('KRW')}`}
                />
                <PriceInfoRow
                  label={t('reservation.cancelDate')}
                  value={moment(paymentInfo?.cancelDate).format('YYYY-MM-DD HH:mm:ss')}
                />
              </>
            ) : (
              <PointsSection>
                <Row width="100%">
                  <>
                    <div>
                      <PointsLabel>{t('reservation.earnedPoints')}</PointsLabel>
                      <div
                        style={{ fontSize: '12px', color: 'rgb(28, 81, 182)', fontWeight: '500' }}
                      >
                        {myAccount?.membershipLevel}({myAccount?.discountRate}%)
                      </div>
                    </div>
                    <PointsValue>
                      {Math.round(
                        ((reservationInfo?.totalAmount || 0) * (myAccount?.discountRate || 1)) /
                          100,
                      )?.toLocaleString()}{' '}
                      {t('won')}
                    </PointsValue>
                  </>
                </Row>
              </PointsSection>
            )}
            <DashedDivider
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 752 2"
              fill="none"
              height="2"
            >
              <path d="M0 1H752" />
            </DashedDivider>
            <TotalSection>
              <TotalHeader>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <TotalTitle>{t('reservation.totalPayment')}</TotalTitle>
                  <VatText>{t('reservation.vatIncluded')}</VatText>
                </div>
                <NumberFormat
                  style={{ color: '#1C51B6', fontSize: '24px', fontWeight: '600' }}
                  value={reservationInfo?.totalAmount || 0}
                  surffix={t('won')}
                />
              </TotalHeader>
            </TotalSection>
          </ContentSection>
        </ReservationContent>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  /* max-width: 792px; */
  flex-direction: column;
  /* width: 100vw; */
`;

const Header = styled.h1`
  color: var(--Grayscale-80, #2d2d2d);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.06px;
  margin: 0;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 19px;
    line-height: 150%;
    letter-spacing: -0.047px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
`;

const ReservationHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ReservationNumber = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.04px;
  align-self: stretch;
  margin: auto 0;
`;

const CopyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--gray-gray-30, #d8d8d8);
  background: var(--Grayscale-0, #fff);
  gap: 4px;
  padding: 6px 8px;
  cursor: pointer;
`;

const CopyIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ButtonText = styled.span`
  color: var(--gray-gray-90, #1d1d1d);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.03px;
`;

const ReservationContent = styled.div`
  position: relative;
  border-radius: 0 0 12px 12px;
  border-top: 2px solid var(--1-f-1-e-1-d, #1f1e1d);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-top: 16px;
  padding: 48px 20px;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BackgroundLogo = styled.svg`
  position: absolute;
  right: 20px;
  bottom: 48px;
  width: 110px;
  height: 110px;
`;

const HostInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const HostDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 240px;
`;

const HostName = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.06px;
  margin: 0;
`;

const HostAddress = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  letter-spacing: -0.03px;
  margin-top: 8px;
`;

const StayInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  padding: 23px 20px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-10, #f0f0f0);
  background: #fff;
  /* gap: 8px; */
`;

const CheckInInfo = styled.div`
  flex: 1;
`;

const CheckInLabel = styled.span`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  font-weight: 500;
`;

const CheckInTime = styled.div`
  color: var(--1-f-1-e-1-d, #1f1e1d);
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
`;

const StayDuration = styled.div`
  padding: 10px;
  border-radius: 100px;
  background: var(--bg_color, #f7f3ef);
  color: var(--4-e-433-c, #4e433c);
  font-size: 14px;
  text-align: center;
`;

const CheckOutInfo = styled.div`
  flex: 1;
  text-align: right;
`;

const CheckOutLabel = styled(CheckInLabel)``;

const CheckOutTime = styled(CheckInTime)``;

const LocationIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const Divider = styled.img`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  border: 1px solid #e4e4e4;
`;

const DashedDivider = styled.svg`
  height: 2px;
  align-self: stretch;
  width: 100%;
  margin: 32px 0;

  path {
    stroke: var(--Grayscale-50, #8e8e8e);
    stroke-width: 1px;
    stroke-dasharray: 4 4;
  }
`;

const AddressSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddressLabel = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04px;
  width: 56px;
`;

const AddressContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 240px;
`;

const AddressText = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 14px;
  letter-spacing: -0.04px;
  flex: 1;
  text-align: left;
`;

const CopyAddressButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--sub-color, #1c51b6);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.03px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const CopyText = styled.span`
  text-decoration: underline;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const InfoLabel = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const InfoValue = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 14px;
  letter-spacing: -0.04px;
  flex: 1;
  text-align: right;
`;

const StatusValue = styled(InfoValue)`
  font-weight: 600;
`;

const SectionTitle = styled.h3`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  margin-bottom: 22px;
`;

const PaymentRow = styled(InfoRow)``;

const PaymentLabel = styled(InfoLabel)``;

const PaymentValue = styled(InfoValue)``;

const PriceSection = styled.div``;

const PriceSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const PriceSectionTitle = styled.h3`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  margin: 0;
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ExpandText = styled.span`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  letter-spacing: -0.03px;
`;

const ExpandIcon = styled.svg<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const PriceRow = styled(InfoRow)``;

const PriceLabel = styled(InfoLabel)``;

const PriceAmount = styled(InfoValue)<{ highlight?: boolean }>`
  font-weight: ${(props) => (props.highlight ? '600' : '400')};
`;

const DiscountRow = styled(PriceRow)``;

const DiscountLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const DiscountBadge = styled.span`
  background: var(--1-f-1-e-1-d, #1f1e1d);
  color: var(--Grayscale-5, #f8f8f8);
  font-size: 12px;
  letter-spacing: -0.03px;
  padding: 3px 6px;
  border-radius: 4px;
`;

const DiscountAmount = styled(PriceAmount)``;

const PointsSection = styled(InfoRow)`
  margin-top: 0;
`;

const PointsLabel = styled(InfoLabel)``;

const PointsValue = styled(InfoValue)``;

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const TotalTitle = styled.h3`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  margin: 0;
`;

const VatText = styled.span`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  letter-spacing: -0.03px;
  display: flex;
  align-items: center;
`;

const TotalAmount = styled.div`
  flex: 1;
  min-width: 240px;
`;

const BrandLogo = styled.img`
  position: absolute;
  right: 12px;
  bottom: 48px;
  width: 118px;
  height: 113px;
  background-blend-mode: hard-light;
`;

export default ReservationDetails;
