import * as React from 'react';
import styled from 'styled-components';

interface AccommodationDetailsProps {
  name: string;
  address: string;
  imageUrl: string;
}

export const AccommodationDetails: React.FC<AccommodationDetailsProps> = ({
  name,
  address,
  imageUrl,
}) => {
  return (
    <>
      <DetailsContainer>
        <AccommodationImage src={imageUrl} alt={name} loading="lazy" />
        <InfoContainer>
          <InfoWrapper>
            <NameWrapper>
              <AccommodationName>{name}</AccommodationName>
              {/* <VerificationIcon
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/755b2477d6da02c7c11f5ca56484c1a3e755dab441fb66eedc1110a0e472bf2e?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
                alt="verification icon"
              /> */}
            </NameWrapper>
            <Address>{address}</Address>
          </InfoWrapper>
        </InfoContainer>
      </DetailsContainer>
    </>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: nowrap;
`;

const AccommodationImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 100px;
  border-radius: 4px;
`;

const InfoContainer = styled.div`
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const InfoWrapper = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 100%;
  flex-direction: column;
  flex: 1;
  margin: auto 0;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const AccommodationName = styled.h2`
  font-size: 17px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  letter-spacing: -0.04px;
  margin: 0;
`;

const VerificationIcon = styled.img`
  aspect-ratio: 1;
  width: 20px;
`;

const Address = styled.p`
  text-align: left;
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
  margin: 6px 0 0 0;
`;
